@font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins-SemiBold.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: Poppins;
    src: url("./assets/fonts/Poppins-Bold.otf") format("opentype");
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}
