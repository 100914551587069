.ReactModal__Body--open {
    overflow: hidden;
}

.modal-content {
    position: absolute;
    // top: 50%;
    // left: 50%;
    // right: auto;
    // bottom: auto;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    // border-radius: 4px;
    outline: none;
    padding: 20px;
    // margin-right: -50%;
    // transform: translate(-50%, -50%);
    bottom: 0;
    width: 100%;
    min-height: calc(100vh / 2);
    border-radius: 20px 20px 0 0;
}

.modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 1000;
    // -webkit-backdrop-filter: blur(14px);
    // backdrop-filter: blur(14px);
    // background-color: rgba(0, 0, 0, 0.09);
}

.close-icon-container {
    height: 30px;
    position: relative;
    .close-icon {
        cursor: pointer;
        position: absolute;
        height: 12px;
        width: 12px;
        right: 0;
    }
}
