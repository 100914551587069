.navbar {
    // background-color: #fe8b55;
}

.navbar h3 {
    display: inline-block;
    text-align: left;
    padding: 10px;
    color: black;
    text-decoration: none;
}

.navbar a {
    display: inline-block;
    padding: 10px;
    color: #fff;
    text-decoration: none;
}

.page-info {
    padding: 10px;
}

.Current {
    color: #2e8b57;
}

.Completed {
    color: #ff6347;
    text-decoration: line-through;
}

.online-block, .offline-block {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 4px;
    z-index: 100;
}

.online-block {
    background-color: #2e8b57;
}

.offline-block {
    background-color: #ff6347;
}

.app-container {
    background: linear-gradient(333.66deg, #ED5E1E 59.05%, #FF9A5A 102%);
    min-height: 100vh;
}
