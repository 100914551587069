body::-webkit-scrollbar {
    display: none;
}

body {
    -ms-overflow-style: none;
    font-size: 16px;
    line-height: normal;
    letter-spacing: 0.2px;
    // background-color: white;
    color: #000000;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", Courier, monospace;
    --webkit-font-smoothing: antialiased;
}

.fs-12 {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
}

.fs-14 {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
}

.fs-24 {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.5px;
}

a {
    text-decoration: none;
}

p {
    margin: 0;
    padding: 0;
}

*, :after, :before {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

input:focus, button:focus, a:focus {
    outline:none;
}
