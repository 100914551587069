.navbar {
    // background-color: #474747;
    // background: linear-gradient(333.66deg, #ED5E1E 59.05%, #FF9A5A 102%);
    padding: 24px 16px 20px 16px;
    // height: 56px;
    // width: 100%;
    // position: relative;
    z-index: 1000;
    .lead-logo {
        display: inline-block;
        width: 88px;
        height: 30px;
    }
    .profile-block {
        float: right;
        .login-block {
            cursor: pointer;
            display: inline-block;
            vertical-align: sub;
            color: #ffffff;
        }
    }
    .left-action {
        height: 44px;
        width: 44px;
        display: inline-block;
        background: #B9B5B2;
        border-radius: 24px;
        vertical-align: middle;
    }
    .text-block {
        margin-left: 8px;
        width: calc(100% - 76px);
        display: inline-block;
        vertical-align: middle;
        span {
            color: #FFFFFF;
            display: block;
            width: inherit;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .fs-14 {
            font-weight: 600;
        }
    }
    .notifications-icon {
        height: 12px;
        width: 18px;
        display: inline-block;
        position: absolute;
        right: 54px;
        top: 16px;
    }
    .right-action {
        height: 24px;
        width: 24px;
        vertical-align: middle;
        display: inline-block;
        cursor: pointer;
        position: relative;
        .right-action-details {
            position: absolute;
            right: 10px;
            top: 28px;
            max-height: 0;
            max-width: 0;
            background-color: #ffffff;
            transition: all .2s ease-in;
            visibility: hidden;
            .fs-12 {
                color: #666666;
                cursor: pointer;
            }
            &.opened {
                max-height: 10000px;
                max-width: 10000px;
                padding: 12px;
                z-index: 1;
                visibility: visible;
            }
        }
    }
}
